.images img {
  cursor: pointer;
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.8);
  display: flex;
}

.container {
  display: flex;
  position: relative;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.overlay img {
  display: block;
  position: relative;
  max-width: 90%;
  max-height: 80%;
  margin: auto auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.overlay span {
  position: absolute;
  top: 3.5rem;
  right: 2rem;
  font-size: 25px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
  opacity: 0.2;
}

.overlay span:hover {
  opacity: 0.5;
}

.overlay-arrows_left {
  padding: 2rem;
  display: flex;
  position: absolute;
  left: 0;
  z-index: 999;
}

.overlay-arrows_left svg {
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: #ffffff;
  opacity: 0.2;
}

.overlay-arrows_left svg:hover {
  opacity: 0.5;
}

.overlay-arrows_right svg {
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: #ffffff;
  opacity: 0.2;
}

.overlay-arrows_right svg:hover {
  opacity: 0.5;
}

.overlay-arrows_right {
  padding: 2rem;
  display: flex;
  position: absolute;
  right: 0;
  z-index: 999;
}
