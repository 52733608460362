@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Goldman:wght@400;700&display=swap');

@layer base {
  body {
    @apply font-[Goldman];
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}
